const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

export const getMasterMenuList = () => {
    const menuList = sessionStorage.getItem('menuMaster');
    if (menuList) {
        return JSON.parse(menuList);
    }
};

export const setMasterMenuList = (masterMenu) => {
    if (masterMenu) {
        sessionStorage.setItem('menuMaster', JSON.stringify(masterMenu));
    }
};

export const getMasterMenuOnlyList = () => {
    const menuList = sessionStorage.getItem('menuOnlyMaster');
    if (menuList) {
        return JSON.parse(menuList);
    }
};

export const setMasterMenuOnlyList = (masterMenu) => {
    if (masterMenu) {
        sessionStorage.setItem('menuOnlyMaster', JSON.stringify(masterMenu));
    }
};

export const getLocalCartItems = () => {
    try {
        const cartItems = JSON.parse(localStorage.getItem('cartItems'));
        return cartItems ? cartItems : [];
    } catch (e) {
        return [];
    }
};

export const makeLocalCartItemsEmpty = () => {
    localStorage.setItem('cartItems', JSON.stringify([]));
};

export const addLocalCartItem = (menuItem) => {
    let items = getLocalCartItems();
    items.push(menuItem);
    items = JSON.stringify(items, getCircularReplacer());
    localStorage.setItem('cartItems', items);
};

export const updateLocalCartItem = (menuItem) => {
    let items = getLocalCartItems();
    items = items.map((item) => {
        if (item.cartItemId === menuItem.cartItemId) {
            item = menuItem;
        }
        return item;
    });
    items = JSON.stringify(items, getCircularReplacer());
    localStorage.setItem('cartItems', items);
};

export const removeLocalCartItem = (cartItemId) => {
    const items = getLocalCartItems();
    const newItems = items.filter((item) => item.cartItemId !== cartItemId);
    localStorage.setItem('cartItems', JSON.stringify(newItems));
};

// Add timestamp to the current item to keep track when was the item added to cart
export const generateCartItem = (item) => {
    const cartItem = JSON.parse(JSON.stringify(item, getCircularReplacer()));
    delete cartItem.cartItems;
    cartItem.cart_timestamp = new Date().toISOString();
    // contains no. of cart item
    cartItem.cart_item_count = 1;
    cartItem.cartItemId = Math.random();
    return cartItem;
};

// Increment/decrement localCartItem
export const updateCartItem = (cartItem) => {
    const items = getLocalCartItems();
    const updatedCartItems = items.map((item) => {
        if (item.cartItemId === cartItem.cartItemId) {
            item = cartItem;
        }
        return item;
    });
    updateAllCartItem(updatedCartItems);
};

// update all  local cart items at once
export const updateAllCartItem = (updatedCartItems) => {
    updatedCartItems = JSON.stringify(updatedCartItems, getCircularReplacer());
    console.log('Updated cart items', updatedCartItems);
    localStorage.setItem('cartItems', updatedCartItems);
};

export const elementInViewport = (el) => {
    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;

    while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
    }

    return (
        top >= window.pageYOffset &&
        left >= window.pageXOffset &&
        top + height <= window.pageYOffset + window.innerHeight &&
        left + width <= window.pageXOffset + window.innerWidth
    );
};

export const convertJSONtoFormData = (data) => {
    const formData = new FormData();

    buildFormData(formData, data);

    return formData;
};

const buildFormData = (formData, data, parentKey) => {
    if (
        data &&
        typeof data === 'object' &&
        !(data instanceof Date) &&
        !(data instanceof File) &&
        !(data instanceof Blob)
    ) {
        Object.keys(data).forEach((key) => {
            buildFormData(
                formData,
                data[key],
                parentKey ? `${parentKey}[${key}]` : key
            );
        });
    } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
    }
};
export const renderPrice = (price = 0, hotelDetails = {}) => {
    return price.toLocaleString('en-US', {
        minimumFractionDigits:
            hotelDetails?.hotel_config?.amount_precise_after_decimal === 0
                ? 0
                : hotelDetails?.hotel_config?.amount_precise_after_decimal || 2,
        maximumFractionDigits:
            hotelDetails?.hotel_config?.amount_precise_after_decimal === 0
                ? 0
                : hotelDetails?.hotel_config?.amount_precise_after_decimal || 2,
    });
};

export const formattedSelectedItems = (connectData) => {
    const payload = [];
    connectData?.categories?.forEach((category) => {
        category.items.forEach((item) => {
            if (item.checked) {
                if (item.type === 'with-count' && item.count > 0) {
                    const obj = {
                        name: item.name,
                        connect_item_id: item.id,
                        quantity: item.count,
                        type: item.type,
                    };
                    payload.push(obj);
                }

                if (item.type === 'open-msg') {
                    const obj2 = {
                        name: item.name,
                        connect_item_id: item.id,
                        description: item.message,
                        type: item.type,
                    };
                    payload.push(obj2);
                }
                if (item.type === 'simple') {
                    const obj3 = {
                        name: item.name,
                        connect_item_id: item.id,
                        description: '',
                        type: item.type,
                    };
                    payload.push(obj3);
                }
            }
        });
    });
    connectData?.without_category_items?.forEach((item) => {
        if (item.checked) {
            if (item.type === 'with-count' && item.count > 0) {
                const obj = {
                    name: item.name,
                    connect_item_id: item.id,
                    quantity: item.count,
                    type: item.type,
                };
                payload.push(obj);
            }

            if (item.type === 'open-msg') {
                const obj2 = {
                    name: item.name,
                    connect_item_id: item.id,
                    description: item.message,
                    type: item.type,
                };
                payload.push(obj2);
            }
            if (item.type === 'simple') {
                const obj3 = {
                    name: item.name,
                    connect_item_id: item.id,
                    description: '',
                    type: item.type,
                };
                payload.push(obj3);
            }
        }
    });
    return payload;
};

export const loadScript = (src, async = true) => {
    return new Promise((resolve, reject) => {
      const existingScript = document.querySelector(`script[src="${src}"]`);  
      if(existingScript){
        resolve();
        return;
      }
      const script = document.createElement('script');
      script.src = src;
      script.async = async;
  
      script.onload = () => resolve({ status: 'success' });
      script.onerror = () => reject({ status: 'error' });
  
      document.head.appendChild(script);
    });
  };


  
