import * as EN from './translations/en.json';
import * as AR from './translations/ar.json';
import * as FR from './translations/fr.json';
import * as RU from './translations/ru.json';
import * as CN from './translations/cn.json';
import * as KO from './translations/ko.json';
import * as JA from './translations/ja.json';

export const USER_LOCATION = 'user_location';
export const TOKEN = 'hotel_token';
export const USER_PROPS = 'userProps';
export const LAST_PAGE_VISIT = 'lastPageVisit';
export const CART_ITEMS = 'cartItems';
// export const SERVICETYPE = 'serviceType';
export const EMAIL_REGEXP = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const authRequiredRoutes = [
    'ird',
    'laundry',
    'spa',
    'checkout-food',
    'checkout-laundry',
    'checkout-spa',
    'laundry-thank-you',
    'spa-thank-you',
    'food-thank-you',
    'place-request',
    'checkout-place-request',
    'request-thank-you',
    'booking',
];
export const countryList = [
    {
        name: 'Canada',
        dial_code: '+1',
        code: 'CA',
        emoji: '🇨🇦',
        code_value: '1',
    },
    {
        name: 'United States',
        dial_code: '+1',
        code: 'US',
        emoji: '🇺🇸',
        code_value: '1',
    },
    {
        name: 'Russia',
        dial_code: '+7',
        code: 'RU',
        emoji: '🇷🇺',
        code_value: '7',
    },
    {
        name: 'Egypt',
        dial_code: '+20',
        code: 'EG',
        emoji: '🇪🇬',
        code_value: '20',
    },
    {
        name: 'South Africa',
        dial_code: '+27',
        code: 'ZA',
        emoji: '🇿🇦',
        code_value: '27',
    },
    {
        name: 'Greece',
        dial_code: '+30',
        code: 'GR',
        emoji: '🇬🇷',
        code_value: '30',
    },
    {
        name: 'Netherlands',
        dial_code: '+31',
        code: 'NL',
        emoji: '🇳🇱',
        code_value: '31',
    },
    {
        name: 'Belgium',
        dial_code: '+32',
        code: 'BE',
        emoji: '🇧🇪',
        code_value: '32',
    },
    {
        name: 'France',
        dial_code: '+33',
        code: 'FR',
        emoji: '🇫🇷',
        code_value: '33',
    },
    {
        name: 'Spain',
        dial_code: '+34',
        code: 'ES',
        emoji: '🇪🇸',
        code_value: '34',
    },
    {
        name: 'Hungary',
        dial_code: '+36',
        code: 'HU',
        emoji: '🇭🇺',
        code_value: '36',
    },
    {
        name: 'Italy',
        dial_code: '+39',
        code: 'IT',
        emoji: '🇮🇹',
        code_value: '39',
    },
    {
        name: 'Romania',
        dial_code: '+40',
        code: 'RO',
        emoji: '🇷🇴',
        code_value: '40',
    },
    {
        name: 'Switzerland',
        dial_code: '+41',
        code: 'CH',
        emoji: '🇨🇭',
        code_value: '41',
    },
    {
        name: 'Austria',
        dial_code: '+43',
        code: 'AT',
        emoji: '🇦🇹',
        code_value: '43',
    },
    {
        name: 'Guernsey',
        dial_code: '+44',
        code: 'GG',
        emoji: '🇬🇬',
        code_value: '44',
    },
    {
        name: 'Isle of Man',
        dial_code: '+44',
        code: 'IM',
        emoji: '🇮🇲',
        code_value: '44',
    },
    {
        name: 'Jersey',
        dial_code: '+44',
        code: 'JE',
        emoji: '🇯🇪',
        code_value: '44',
    },
    {
        name: 'United Kingdom',
        dial_code: '+44',
        code: 'UK',
        emoji: '🇬🇧',
        code_value: '44',
    },
    {
        name: 'Denmark',
        dial_code: '+45',
        code: 'DK',
        emoji: '🇩🇰',
        code_value: '45',
    },
    {
        name: 'Sweden',
        dial_code: '+46',
        code: 'SE',
        emoji: '🇸🇪',
        code_value: '46',
    },
    {
        name: 'Norway',
        dial_code: '+47',
        code: 'NO',
        emoji: '🇳🇴',
        code_value: '47',
    },
    {
        name: 'Svalbard and Jan Mayen',
        dial_code: '+47',
        code: 'SJ',
        emoji: '🇳🇴',
        code_value: '47',
    },
    {
        name: 'Poland',
        dial_code: '+48',
        code: 'PL',
        emoji: '🇵🇱',
        code_value: '48',
    },
    {
        name: 'Germany',
        dial_code: '+49',
        code: 'DE',
        emoji: '🇩🇪',
        code_value: '49',
    },
    {
        name: 'Peru',
        dial_code: '+51',
        code: 'PE',
        emoji: '🇵🇪',
        code_value: '51',
    },
    {
        name: 'Mexico',
        dial_code: '+52',
        code: 'MX',
        emoji: '🇲🇽',
        code_value: '52',
    },
    {
        name: 'Cuba',
        dial_code: '+53',
        code: 'CU',
        emoji: '🇨🇺',
        code_value: '53',
    },
    {
        name: 'Argentina',
        dial_code: '+54',
        code: 'AR',
        emoji: '🇦🇷',
        code_value: '54',
    },
    {
        name: 'Brazil',
        dial_code: '+55',
        code: 'BR',
        emoji: '🇧🇷',
        code_value: '55',
    },
    {
        name: 'Chile',
        dial_code: '+56',
        code: 'CL',
        emoji: '🇨🇱',
        code_value: '56',
    },
    {
        name: 'Colombia',
        dial_code: '+57',
        code: 'CO',
        emoji: '🇨🇴',
        code_value: '57',
    },
    {
        name: 'Venezuela, Bolivarian Republic of Venezuela',
        dial_code: '+58',
        code: 'VE',
        emoji: '🇻🇪',
        code_value: '58',
    },
    {
        name: 'Malaysia',
        dial_code: '+60',
        code: 'MY',
        emoji: '🇲🇾',
        code_value: '60',
    },
    {
        name: 'Australia',
        dial_code: '+61',
        code: 'AU',
        emoji: '🇦🇺',
        code_value: '61',
    },
    {
        name: 'Christmas Island',
        dial_code: '+61',
        code: 'CX',
        emoji: '🇨🇽',
        code_value: '61',
    },
    {
        name: 'Cocos (Keeling) Islands',
        dial_code: '+61',
        code: 'CC',
        emoji: '🇨🇨',
        code_value: '61',
    },
    {
        name: 'Indonesia',
        dial_code: '+62',
        code: 'ID',
        emoji: '🇮🇩',
        code_value: '62',
    },
    {
        name: 'Philippines',
        dial_code: '+63',
        code: 'PH',
        emoji: '🇵🇭',
        code_value: '63',
    },
    {
        name: 'New Zealand',
        dial_code: '+64',
        code: 'NZ',
        emoji: '🇳🇿',
        code_value: '64',
    },
    {
        name: 'Singapore',
        dial_code: '+65',
        code: 'SG',
        emoji: '🇸🇬',
        code_value: '65',
    },
    {
        name: 'Thailand',
        dial_code: '+66',
        code: 'TH',
        emoji: '🇹🇭',
        code_value: '66',
    },
    {
        name: 'Kazakhstan',
        dial_code: '+77',
        code: 'KZ',
        emoji: '🇰🇿',
        code_value: '77',
    },
    {
        name: 'Japan',
        dial_code: '+81',
        code: 'JP',
        emoji: '🗾',
        code_value: '81',
    },
    {
        name: 'Korea, Republic of South Korea',
        dial_code: '+82',
        code: 'KR',
        emoji: '🇰🇷',
        code_value: '82',
    },
    {
        name: 'Vietnam',
        dial_code: '+84',
        code: 'VN',
        emoji: '🇻🇳',
        code_value: '84',
    },
    {
        name: 'China',
        dial_code: '+86',
        code: 'CN',
        emoji: '🇨🇳',
        code_value: '86',
    },
    {
        name: 'Turkey',
        dial_code: '+90',
        code: 'TR',
        emoji: '🇹🇷',
        code_value: '90',
    },
    {
        name: 'India',
        dial_code: '+91',
        code: 'IN',
        emoji: '🇮🇳',
        code_value: '91',
    },
    {
        name: 'Pakistan',
        dial_code: '+92',
        code: 'PK',
        emoji: '🇵🇰',
        code_value: '92',
    },
    {
        name: 'Afghanistan',
        dial_code: '+93',
        code: 'AF',
        emoji: '🇦🇫',
        code_value: '93',
    },
    {
        name: 'Sri Lanka',
        dial_code: '+94',
        code: 'LK',
        emoji: '🇱🇰',
        code_value: '94',
    },
    {
        name: 'Myanmar',
        dial_code: '+95',
        code: 'MM',
        emoji: '🇲🇲',
        code_value: '95',
    },
    {
        name: 'Iran, Islamic Republic of Persian Gulf',
        dial_code: '+98',
        code: 'IR',
        emoji: '🇮🇷',
        code_value: '98',
    },
    {
        name: 'South Sudan',
        dial_code: '+211',
        code: 'SS',
        emoji: '🇸🇸',
        code_value: '211',
    },
    {
        name: 'Morocco',
        dial_code: '+212',
        code: 'MA',
        emoji: '🇲🇦',
        code_value: '212',
    },
    {
        name: 'Algeria',
        dial_code: '+213',
        code: 'DZ',
        emoji: '🇩🇿',
        code_value: '213',
    },
    {
        name: 'Tunisia',
        dial_code: '+216',
        code: 'TN',
        emoji: '🇹🇳',
        code_value: '216',
    },
    {
        name: 'Libyan Arab Jamahiriya',
        dial_code: '+218',
        code: 'LY',
        emoji: '🇱🇾',
        code_value: '218',
    },
    {
        name: 'Gambia',
        dial_code: '+220',
        code: 'GM',
        emoji: '🇬🇲',
        code_value: '220',
    },
    {
        name: 'Senegal',
        dial_code: '+221',
        code: 'SN',
        emoji: '🇸🇳',
        code_value: '221',
    },
    {
        name: 'Mauritania',
        dial_code: '+222',
        code: 'MR',
        emoji: '🇲🇷',
        code_value: '222',
    },
    {
        name: 'Mali',
        dial_code: '+223',
        code: 'ML',
        emoji: '🇲🇱',
        code_value: '223',
    },
    {
        name: 'Guinea',
        dial_code: '+224',
        code: 'GN',
        emoji: '🇬🇳',
        code_value: '224',
    },
    {
        name: "Cote d'Ivoire",
        dial_code: '+225',
        code: 'CI',
        emoji: '🇨🇮',
        code_value: '225',
    },
    {
        name: 'Burkina Faso',
        dial_code: '+226',
        code: 'BF',
        emoji: '🇧🇫',
        code_value: '226',
    },
    {
        name: 'Niger',
        dial_code: '+227',
        code: 'NE',
        emoji: '🇳🇪',
        code_value: '227',
    },
    {
        name: 'Togo',
        dial_code: '+228',
        code: 'TG',
        emoji: '🇹🇬',
        code_value: '228',
    },
    {
        name: 'Benin',
        dial_code: '+229',
        code: 'BJ',
        emoji: '🇧🇯',
        code_value: '229',
    },
    {
        name: 'Mauritius',
        dial_code: '+230',
        code: 'MU',
        emoji: '🇲🇺',
        code_value: '230',
    },
    {
        name: 'Liberia',
        dial_code: '+231',
        code: 'LR',
        emoji: '🇱🇷',
        code_value: '231',
    },
    {
        name: 'Sierra Leone',
        dial_code: '+232',
        code: 'SL',
        emoji: '🇸🇱',
        code_value: '232',
    },
    {
        name: 'Ghana',
        dial_code: '+233',
        code: 'GH',
        emoji: '🇬🇭',
        code_value: '233',
    },
    {
        name: 'Nigeria',
        dial_code: '+234',
        code: 'NG',
        emoji: '🇳🇬',
        code_value: '234',
    },
    {
        name: 'Chad',
        dial_code: '+235',
        code: 'TD',
        emoji: '🇹🇩',
        code_value: '235',
    },
    {
        name: 'Central African Republic',
        dial_code: '+236',
        code: 'CF',
        emoji: '🇨🇫',
        code_value: '236',
    },
    {
        name: 'Cameroon',
        dial_code: '+237',
        code: 'CM',
        emoji: '🇨🇲',
        code_value: '237',
    },
    {
        name: 'Cape Verde',
        dial_code: '+238',
        code: 'CV',
        emoji: '🇨🇻',
        code_value: '238',
    },
    {
        name: 'Sao Tome and Principe',
        dial_code: '+239',
        code: 'ST',
        emoji: '🇸🇹',
        code_value: '239',
    },
    {
        name: 'Equatorial Guinea',
        dial_code: '+240',
        code: 'GQ',
        emoji: '🇬🇶',
        code_value: '240',
    },
    {
        name: 'Gabon',
        dial_code: '+241',
        code: 'GA',
        emoji: '🇬🇦',
        code_value: '241',
    },
    {
        name: 'Congo',
        dial_code: '+242',
        code: 'CG',
        emoji: '🇨🇬',
        code_value: '242',
    },
    {
        name: 'Congo, The Democratic Republic of the Congo',
        dial_code: '+243',
        code: 'CD',
        emoji: '🇨🇩',
        code_value: '243',
    },
    {
        name: 'Angola',
        dial_code: '+244',
        code: 'AO',
        emoji: '🇦🇴',
        code_value: '244',
    },
    {
        name: 'Guinea-Bissau',
        dial_code: '+245',
        code: 'GW',
        emoji: '🇬🇼',
        code_value: '245',
    },
    {
        name: 'British Indian Ocean Territory',
        dial_code: '+246',
        code: 'IO',
        emoji: '🇮🇴',
        code_value: '246',
    },
    {
        name: 'Seychelles',
        dial_code: '+248',
        code: 'SC',
        emoji: '🇸🇨',
        code_value: '248',
    },
    {
        name: 'Sudan',
        dial_code: '+249',
        code: 'SD',
        emoji: '🇸🇩',
        code_value: '249',
    },
    {
        name: 'Rwanda',
        dial_code: '+250',
        code: 'RW',
        emoji: '🇷🇼',
        code_value: '250',
    },
    {
        name: 'Ethiopia',
        dial_code: '+251',
        code: 'ET',
        emoji: '🇪🇹',
        code_value: '251',
    },
    {
        name: 'Somalia',
        dial_code: '+252',
        code: 'SO',
        emoji: '🇸🇴',
        code_value: '252',
    },
    {
        name: 'Djibouti',
        dial_code: '+253',
        code: 'DJ',
        emoji: '🇩🇯',
        code_value: '253',
    },
    {
        name: 'Kenya',
        dial_code: '+254',
        code: 'KE',
        emoji: '🇰🇪',
        code_value: '254',
    },
    {
        name: 'Tanzania, United Republic of Tanzania',
        dial_code: '+255',
        code: 'TZ',
        emoji: '🇹🇿',
        code_value: '255',
    },
    {
        name: 'Uganda',
        dial_code: '+256',
        code: 'UG',
        emoji: '🇺🇬',
        code_value: '256',
    },
    {
        name: 'Burundi',
        dial_code: '+257',
        code: 'BI',
        emoji: '🇧🇮',
        code_value: '257',
    },
    {
        name: 'Mozambique',
        dial_code: '+258',
        code: 'MZ',
        emoji: '🇲🇿',
        code_value: '258',
    },
    {
        name: 'Zambia',
        dial_code: '+260',
        code: 'ZM',
        emoji: '🇿🇲',
        code_value: '260',
    },
    {
        name: 'Madagascar',
        dial_code: '+261',
        code: 'MG',
        emoji: '🇲🇬',
        code_value: '261',
    },
    {
        name: 'Mayotte',
        dial_code: '+262',
        code: 'YT',
        emoji: '🇾🇹',
        code_value: '262',
    },
    {
        name: 'Reunion',
        dial_code: '+262',
        code: 'RE',
        emoji: '🇷🇪',
        code_value: '262',
    },
    {
        name: 'Zimbabwe',
        dial_code: '+263',
        code: 'ZW',
        emoji: '🇿🇼',
        code_value: '263',
    },
    {
        name: 'Namibia',
        dial_code: '+264',
        code: 'NA',
        emoji: '🇳🇦',
        code_value: '264',
    },
    {
        name: 'Malawi',
        dial_code: '+265',
        code: 'MW',
        emoji: '🇲🇼',
        code_value: '265',
    },
    {
        name: 'Lesotho',
        dial_code: '+266',
        code: 'LS',
        emoji: '🇱🇸',
        code_value: '266',
    },
    {
        name: 'Botswana',
        dial_code: '+267',
        code: 'BW',
        emoji: '🇧🇼',
        code_value: '267',
    },
    {
        name: 'Swaziland',
        dial_code: '+268',
        code: 'SZ',
        emoji: '🇸🇿',
        code_value: '268',
    },
    {
        name: 'Comoros',
        dial_code: '+269',
        code: 'KM',
        emoji: '🇰🇲',
        code_value: '269',
    },
    {
        name: 'Saint Helena, Ascension and Tristan Da Cunha',
        dial_code: '+290',
        code: 'SH',
        emoji: '🇸🇭',
        code_value: '290',
    },
    {
        name: 'Eritrea',
        dial_code: '+291',
        code: 'ER',
        emoji: '🇪🇷',
        code_value: '291',
    },
    {
        name: 'Aruba',
        dial_code: '+297',
        code: 'AW',
        emoji: '🇦🇼',
        code_value: '297',
    },
    {
        name: 'Faroe Islands',
        dial_code: '+298',
        code: 'FO',
        emoji: '🇫🇴',
        code_value: '298',
    },
    {
        name: 'Greenland',
        dial_code: '+299',
        code: 'GL',
        emoji: '🇬🇱',
        code_value: '299',
    },
    {
        name: 'Cayman Islands',
        dial_code: '+ 345',
        code: 'KY',
        emoji: '🇰🇾',
        code_value: ' 345',
    },
    {
        name: 'Gibraltar',
        dial_code: '+350',
        code: 'GI',
        emoji: '🇬🇮',
        code_value: '350',
    },
    {
        name: 'Portugal',
        dial_code: '+351',
        code: 'PT',
        emoji: '🇵🇹',
        code_value: '351',
    },
    {
        name: 'Luxembourg',
        dial_code: '+352',
        code: 'LU',
        emoji: '🇱🇺',
        code_value: '352',
    },
    {
        name: 'Ireland',
        dial_code: '+353',
        code: 'IE',
        emoji: '🇮🇪',
        code_value: '353',
    },
    {
        name: 'Iceland',
        dial_code: '+354',
        code: 'IS',
        emoji: '🇮🇸',
        code_value: '354',
    },
    {
        name: 'Albania',
        dial_code: '+355',
        code: 'AL',
        emoji: '🇦🇱',
        code_value: '355',
    },
    {
        name: 'Malta',
        dial_code: '+356',
        code: 'MT',
        emoji: '🇲🇹',
        code_value: '356',
    },
    {
        name: 'Cyprus',
        dial_code: '+357',
        code: 'CY',
        emoji: '🇨🇾',
        code_value: '357',
    },
    {
        name: 'Aland Islands',
        dial_code: '+358',
        code: 'AX',
        emoji: '🇦🇽',
        code_value: '358',
    },
    {
        name: 'Finland',
        dial_code: '+358',
        code: 'FI',
        emoji: '🇫🇮',
        code_value: '358',
    },
    {
        name: 'Bulgaria',
        dial_code: '+359',
        code: 'BG',
        emoji: '🇧🇬',
        code_value: '359',
    },
    {
        name: 'Lithuania',
        dial_code: '+370',
        code: 'LT',
        emoji: '🇱🇹',
        code_value: '370',
    },
    {
        name: 'Latvia',
        dial_code: '+371',
        code: 'LV',
        emoji: '🇱🇻',
        code_value: '371',
    },
    {
        name: 'Estonia',
        dial_code: '+372',
        code: 'EE',
        emoji: '🇪🇪',
        code_value: '372',
    },
    {
        name: 'Moldova',
        dial_code: '+373',
        code: 'MD',
        emoji: '🇲🇩',
        code_value: '373',
    },
    {
        name: 'Armenia',
        dial_code: '+374',
        code: 'AM',
        emoji: '🇦🇲',
        code_value: '374',
    },
    {
        name: 'Belarus',
        dial_code: '+375',
        code: 'BY',
        emoji: '🇧🇾',
        code_value: '375',
    },
    {
        name: 'Andorra',
        dial_code: '+376',
        code: 'AD',
        emoji: '🇦🇩',
        code_value: '376',
    },
    {
        name: 'Monaco',
        dial_code: '+377',
        code: 'MC',
        emoji: '🇲🇨',
        code_value: '377',
    },
    {
        name: 'San Marino',
        dial_code: '+378',
        code: 'SM',
        emoji: '🇸🇲',
        code_value: '378',
    },
    {
        name: 'Holy See (Vatican City State)',
        dial_code: '+379',
        code: 'VA',
        emoji: '🇻🇦',
        code_value: '379',
    },
    {
        name: 'Ukraine',
        dial_code: '+380',
        code: 'UA',
        emoji: '🇺🇦',
        code_value: '380',
    },
    {
        name: 'Serbia',
        dial_code: '+381',
        code: 'RS',
        emoji: '🇷🇸',
        code_value: '381',
    },
    {
        name: 'Montenegro',
        dial_code: '+382',
        code: 'ME',
        emoji: '🇲🇪',
        code_value: '382',
    },
    {
        name: 'Croatia',
        dial_code: '+385',
        code: 'HR',
        emoji: '🇭🇷',
        code_value: '385',
    },
    {
        name: 'Slovenia',
        dial_code: '+386',
        code: 'SI',
        emoji: '🇸🇮',
        code_value: '386',
    },
    {
        name: 'Bosnia and Herzegovina',
        dial_code: '+387',
        code: 'BA',
        emoji: '🇧🇦',
        code_value: '387',
    },
    {
        name: 'Macedonia',
        dial_code: '+389',
        code: 'MK',
        emoji: '🇲🇰',
        code_value: '389',
    },
    {
        name: 'Czech Republic',
        dial_code: '+420',
        code: 'CZ',
        emoji: '🇨🇿',
        code_value: '420',
    },
    {
        name: 'Slovakia',
        dial_code: '+421',
        code: 'SK',
        emoji: '🇸🇰',
        code_value: '421',
    },
    {
        name: 'Liechtenstein',
        dial_code: '+423',
        code: 'LI',
        emoji: '🇱🇮',
        code_value: '423',
    },
    {
        name: 'Falkland Islands (Malvinas)',
        dial_code: '+500',
        code: 'FK',
        emoji: '🇫🇰',
        code_value: '500',
    },
    {
        name: 'South Georgia and the South Sandwich Islands',
        dial_code: '+500',
        code: 'GS',
        emoji: '🇬🇸',
        code_value: '500',
    },
    {
        name: 'Belize',
        dial_code: '+501',
        code: 'BZ',
        emoji: '🇧🇿',
        code_value: '501',
    },
    {
        name: 'Guatemala',
        dial_code: '+502',
        code: 'GT',
        emoji: '🇬🇹',
        code_value: '502',
    },
    {
        name: 'El Salvador',
        dial_code: '+503',
        code: 'SV',
        emoji: '🇸🇻',
        code_value: '503',
    },
    {
        name: 'Honduras',
        dial_code: '+504',
        code: 'HN',
        emoji: '🇭🇳',
        code_value: '504',
    },
    {
        name: 'Nicaragua',
        dial_code: '+505',
        code: 'NI',
        emoji: '🇳🇮',
        code_value: '505',
    },
    {
        name: 'Costa Rica',
        dial_code: '+506',
        code: 'CR',
        emoji: '🇨🇷',
        code_value: '506',
    },
    {
        name: 'Panama',
        dial_code: '+507',
        code: 'PA',
        emoji: '🇵🇦',
        code_value: '507',
    },
    {
        name: 'Saint Pierre and Miquelon',
        dial_code: '+508',
        code: 'PM',
        emoji: '🇵🇲',
        code_value: '508',
    },
    {
        name: 'Haiti',
        dial_code: '+509',
        code: 'HT',
        emoji: '🇭🇹',
        code_value: '509',
    },
    {
        name: 'Guadeloupe',
        dial_code: '+590',
        code: 'GP',
        emoji: '🇬🇵',
        code_value: '590',
    },
    {
        name: 'Saint Barthelemy',
        dial_code: '+590',
        code: 'BL',
        emoji: '🇧🇱',
        code_value: '590',
    },
    {
        name: 'Saint Martin',
        dial_code: '+590',
        code: 'MF',
        emoji: '🇫🇷',
        code_value: '590',
    },
    {
        name: 'Bolivia, Plurinational State of',
        dial_code: '+591',
        code: 'BO',
        emoji: '🇧🇴',
        code_value: '591',
    },
    {
        name: 'Ecuador',
        dial_code: '+593',
        code: 'EC',
        emoji: '🇪🇨',
        code_value: '593',
    },
    {
        name: 'French Guiana',
        dial_code: '+594',
        code: 'GF',
        emoji: '🇬🇫',
        code_value: '594',
    },
    {
        name: 'Guyana',
        dial_code: '+595',
        code: 'GY',
        emoji: '🇬🇾',
        code_value: '595',
    },
    {
        name: 'Paraguay',
        dial_code: '+595',
        code: 'PY',
        emoji: '🇵🇾',
        code_value: '595',
    },
    {
        name: 'Martinique',
        dial_code: '+596',
        code: 'MQ',
        emoji: '🇲🇶',
        code_value: '596',
    },
    {
        name: 'Suriname',
        dial_code: '+597',
        code: 'SR',
        emoji: '🇸🇷',
        code_value: '597',
    },
    {
        name: 'Uruguay',
        dial_code: '+598',
        code: 'UY',
        emoji: '🇺🇾',
        code_value: '598',
    },
    {
        name: 'Netherlands Antilles',
        dial_code: '+599',
        code: 'AN',
        emoji: '🇳🇱',
        code_value: '599',
    },
    {
        name: 'Timor-Leste',
        dial_code: '+670',
        code: 'TL',
        emoji: '🇹🇱',
        code_value: '670',
    },
    {
        name: 'Antarctica',
        dial_code: '+672',
        code: 'AQ',
        emoji: '🇦🇶',
        code_value: '672',
    },
    {
        name: 'Norfolk Island',
        dial_code: '+672',
        code: 'NF',
        emoji: '🇳🇫',
        code_value: '672',
    },
    {
        name: 'Brunei Darussalam',
        dial_code: '+673',
        code: 'BN',
        emoji: '🇧🇳',
        code_value: '673',
    },
    {
        name: 'Nauru',
        dial_code: '+674',
        code: 'NR',
        emoji: '🇳🇷',
        code_value: '674',
    },
    {
        name: 'Papua New Guinea',
        dial_code: '+675',
        code: 'PG',
        emoji: '🇵🇬',
        code_value: '675',
    },
    {
        name: 'Tonga',
        dial_code: '+676',
        code: 'TO',
        emoji: '🇹🇴',
        code_value: '676',
    },
    {
        name: 'Solomon Islands',
        dial_code: '+677',
        code: 'SB',
        emoji: '🇸🇧',
        code_value: '677',
    },
    {
        name: 'Vanuatu',
        dial_code: '+678',
        code: 'VU',
        emoji: '🇻🇺',
        code_value: '678',
    },
    {
        name: 'Fiji',
        dial_code: '+679',
        code: 'FJ',
        emoji: '🇫🇯',
        code_value: '679',
    },
    {
        name: 'Palau',
        dial_code: '+680',
        code: 'PW',
        emoji: '🇵🇼',
        code_value: '680',
    },
    {
        name: 'Wallis and Futuna',
        dial_code: '+681',
        code: 'WF',
        emoji: '🇼🇫',
        code_value: '681',
    },
    {
        name: 'Cook Islands',
        dial_code: '+682',
        code: 'CK',
        emoji: '🇨🇰',
        code_value: '682',
    },
    {
        name: 'Niue',
        dial_code: '+683',
        code: 'NU',
        emoji: '🇳🇺',
        code_value: '683',
    },
    {
        name: 'Samoa',
        dial_code: '+685',
        code: 'WS',
        emoji: '🇼🇸',
        code_value: '685',
    },
    {
        name: 'Kiribati',
        dial_code: '+686',
        code: 'KI',
        emoji: '🇰🇮',
        code_value: '686',
    },
    {
        name: 'New Caledonia',
        dial_code: '+687',
        code: 'NC',
        emoji: '🇳🇨',
        code_value: '687',
    },
    {
        name: 'Tuvalu',
        dial_code: '+688',
        code: 'TV',
        emoji: '🇹🇻',
        code_value: '688',
    },
    {
        name: 'French Polynesia',
        dial_code: '+689',
        code: 'PF',
        emoji: '🇵🇫',
        code_value: '689',
    },
    {
        name: 'Tokelau',
        dial_code: '+690',
        code: 'TK',
        emoji: '🇹🇰',
        code_value: '690',
    },
    {
        name: 'Micronesia, Federated States of Micronesia',
        dial_code: '+691',
        code: 'FM',
        emoji: '🇫🇲',
        code_value: '691',
    },
    {
        name: 'Marshall Islands',
        dial_code: '+692',
        code: 'MH',
        emoji: '🇲🇭',
        code_value: '692',
    },
    {
        name: "Korea, Democratic People's Republic of Korea",
        dial_code: '+850',
        code: 'KP',
        emoji: '🇰🇵',
        code_value: '850',
    },
    {
        name: 'Hong Kong',
        dial_code: '+852',
        code: 'HK',
        emoji: '🇭🇰',
        code_value: '852',
    },
    {
        name: 'Macao',
        dial_code: '+853',
        code: 'MO',
        emoji: '🇲🇴',
        code_value: '853',
    },
    {
        name: 'Cambodia',
        dial_code: '+855',
        code: 'KH',
        emoji: '🇰🇭',
        code_value: '855',
    },
    {
        name: 'Laos',
        dial_code: '+856',
        code: 'LA',
        emoji: '🇱🇦',
        code_value: '856',
    },
    {
        name: 'Pitcairn',
        dial_code: '+872',
        code: 'PN',
        emoji: '🇵🇳',
        code_value: '872',
    },
    {
        name: 'Bangladesh',
        dial_code: '+880',
        code: 'BD',
        emoji: '🇧🇩',
        code_value: '880',
    },
    {
        name: 'Taiwan',
        dial_code: '+886',
        code: 'TW',
        emoji: '🇹🇼',
        code_value: '886',
    },
    {
        name: 'Maldives',
        dial_code: '+960',
        code: 'MV',
        emoji: '🇲🇻',
        code_value: '960',
    },
    {
        name: 'Lebanon',
        dial_code: '+961',
        code: 'LB',
        emoji: '🇱🇧',
        code_value: '961',
    },
    {
        name: 'Jordan',
        dial_code: '+962',
        code: 'JO',
        emoji: '🇯🇴',
        code_value: '962',
    },
    {
        name: 'Syrian Arab Republic',
        dial_code: '+963',
        code: 'SY',
        emoji: '🇸🇾',
        code_value: '963',
    },
    {
        name: 'Iraq',
        dial_code: '+964',
        code: 'IQ',
        emoji: '🇮🇶',
        code_value: '964',
    },
    {
        name: 'Kuwait',
        dial_code: '+965',
        code: 'KW',
        emoji: '🇰🇼',
        code_value: '965',
    },
    {
        name: 'Saudi Arabia',
        dial_code: '+966',
        code: 'SA',
        emoji: '🇸🇦',
        code_value: '966',
    },
    {
        name: 'Yemen',
        dial_code: '+967',
        code: 'YE',
        emoji: '🇾🇪',
        code_value: '967',
    },
    {
        name: 'Oman',
        dial_code: '+968',
        code: 'OM',
        emoji: '🇴🇲',
        code_value: '968',
    },
    {
        name: 'Palestinian Territory, Occupied',
        dial_code: '+970',
        code: 'PS',
        emoji: '🇵🇸',
        code_value: '970',
    },
    {
        name: 'United Arab Emirates',
        dial_code: '+971',
        code: 'AE',
        emoji: '🇦🇪',
        code_value: '971',
    },
    {
        name: 'Israel',
        dial_code: '+972',
        code: 'IL',
        emoji: '🇮🇱',
        code_value: '972',
    },
    {
        name: 'Bahrain',
        dial_code: '+973',
        code: 'BH',
        emoji: '🇧🇭',
        code_value: '973',
    },
    {
        name: 'Qatar',
        dial_code: '+974',
        code: 'QA',
        emoji: '🇶🇦',
        code_value: '974',
    },
    {
        name: 'Bhutan',
        dial_code: '+975',
        code: 'BT',
        emoji: '🇧🇹',
        code_value: '975',
    },
    {
        name: 'Mongolia',
        dial_code: '+976',
        code: 'MN',
        emoji: '🇲🇳',
        code_value: '976',
    },
    {
        name: 'Nepal',
        dial_code: '+977',
        code: 'NP',
        emoji: '🇳🇵',
        code_value: '977',
    },
    {
        name: 'Tajikistan',
        dial_code: '+992',
        code: 'TJ',
        emoji: '🇹🇯',
        code_value: '992',
    },
    {
        name: 'Turkmenistan',
        dial_code: '+993',
        code: 'TM',
        emoji: '🇹🇲',
        code_value: '993',
    },
    {
        name: 'Azerbaijan',
        dial_code: '+994',
        code: 'AZ',
        emoji: '🇦🇿',
        code_value: '994',
    },
    {
        name: 'Georgia',
        dial_code: '+995',
        code: 'GE',
        emoji: '🇬🇪',
        code_value: '995',
    },
    {
        name: 'Kyrgyzstan',
        dial_code: '+996',
        code: 'KG',
        emoji: '🇰🇬',
        code_value: '996',
    },
    {
        name: 'Uzbekistan',
        dial_code: '+998',
        code: 'UZ',
        emoji: '🇺🇿',
        code_value: '998',
    },
    {
        name: 'Bahamas',
        dial_code: '+1242',
        code: 'BS',
        emoji: '🇧🇸',
        code_value: '1242',
    },
    {
        name: 'Barbados',
        dial_code: '+1246',
        code: 'BB',
        emoji: '🇧🇧',
        code_value: '1246',
    },
    {
        name: 'Anguilla',
        dial_code: '+1264',
        code: 'AI',
        emoji: '🇦🇮',
        code_value: '1264',
    },
    {
        name: 'Antigua and Barbuda',
        dial_code: '+1268',
        code: 'AG',
        emoji: '🇦🇬',
        code_value: '1268',
    },
    {
        name: 'Virgin Islands, British',
        dial_code: '+1284',
        code: 'VG',
        emoji: '🇻🇬',
        code_value: '1284',
    },
    {
        name: 'Virgin Islands, U.S.',
        dial_code: '+1340',
        code: 'VI',
        emoji: '🇻🇮',
        code_value: '1340',
    },
    {
        name: 'Bermuda',
        dial_code: '+1441',
        code: 'BM',
        emoji: '🇧🇲',
        code_value: '1441',
    },
    {
        name: 'Grenada',
        dial_code: '+1473',
        code: 'GD',
        emoji: '🇬🇩',
        code_value: '1473',
    },
    {
        name: 'Turks and Caicos Islands',
        dial_code: '+1649',
        code: 'TC',
        emoji: '🇹🇨',
        code_value: '1649',
    },
    {
        name: 'Montserrat',
        dial_code: '+1664',
        code: 'MS',
        emoji: '🇲🇸',
        code_value: '1664',
    },
    {
        name: 'Northern Mariana Islands',
        dial_code: '+1670',
        code: 'MP',
        emoji: '🇲🇵',
        code_value: '1670',
    },
    {
        name: 'Guam',
        dial_code: '+1671',
        code: 'GU',
        emoji: '🇬🇺',
        code_value: '1671',
    },
    {
        name: 'American Samoa',
        dial_code: '+1684',
        code: 'AS',
        emoji: '🇦🇸',
        code_value: '1684',
    },
    {
        name: 'Saint Lucia',
        dial_code: '+1758',
        code: 'LC',
        emoji: '🇱🇨',
        code_value: '1758',
    },
    {
        name: 'Dominica',
        dial_code: '+1767',
        code: 'DM',
        emoji: '🇩🇲',
        code_value: '1767',
    },
    {
        name: 'Saint Vincent and the Grenadines',
        dial_code: '+1784',
        code: 'VC',
        emoji: '🇻🇨',
        code_value: '1784',
    },
    {
        name: 'Dominican Republic',
        dial_code: '+1849',
        code: 'DO',
        emoji: '🇩🇴',
        code_value: '1849',
    },
    {
        name: 'Trinidad and Tobago',
        dial_code: '+1868',
        code: 'TT',
        emoji: '🇹🇹',
        code_value: '1868',
    },
    {
        name: 'Saint Kitts and Nevis',
        dial_code: '+1869',
        code: 'KN',
        emoji: '🇰🇳',
        code_value: '1869',
    },
    {
        name: 'Jamaica',
        dial_code: '+1876',
        code: 'JM',
        emoji: '🇯🇲',
        code_value: '1876',
    },
    {
        name: 'Puerto Rico',
        dial_code: '+1939',
        code: 'PR',
        emoji: '🇵🇷',
        code_value: '1939',
    },
];
export const FLAG_LIST = {
    CA: '🇨🇦',
    US: '🇺🇸',
    EN: '🇺🇸',
    RU: '🇷🇺',
    EG: '🇪🇬',
    ZA: '🇿🇦',
    GR: '🇬🇷',
    NL: '🇳🇱',
    BE: '🇧🇪',
    FR: '🇫🇷',
    ES: '🇪🇸',
    HU: '🇭🇺',
    IT: '🇮🇹',
    RO: '🇷🇴',
    CH: '🇨🇭',
    AT: '🇦🇹',
    GG: '🇬🇬',
    IM: '🇮🇲',
    JE: '🇯🇪',
    UK: '🇬🇧',
    DK: '🇩🇰',
    SE: '🇸🇪',
    NO: '🇳🇴',
    SJ: '🇳🇴',
    PL: '🇵🇱',
    DE: '🇩🇪',
    PE: '🇵🇪',
    MX: '🇲🇽',
    CU: '🇨🇺',
    AR: '🇦🇷',
    BR: '🇧🇷',
    CL: '🇨🇱',
    CO: '🇨🇴',
    VE: '🇻🇪',
    MY: '🇲🇾',
    AU: '🇦🇺',
    CX: '🇨🇽',
    CC: '🇨🇨',
    ID: '🇮🇩',
    PH: '🇵🇭',
    NZ: '🇳🇿',
    SG: '🇸🇬',
    TH: '🇹🇭',
    KZ: '🇰🇿',
    JP: '🗾',
    KR: '🇰🇷',
    VN: '🇻🇳',
    CN: '🇨🇳',
    TR: '🇹🇷',
    IN: '🇮🇳',
    HI: '🇮🇳',
    PK: '🇵🇰',
    AF: '🇦🇫',
    LK: '🇱🇰',
    MM: '🇲🇲',
    IR: '🇮🇷',
    SS: '🇸🇸',
    MA: '🇲🇦',
    DZ: '🇩🇿',
    TN: '🇹🇳',
    LY: '🇱🇾',
    GM: '🇬🇲',
    SN: '🇸🇳',
    MR: '🇲🇷',
    ML: '🇲🇱',
    GN: '🇬🇳',
    CI: '🇨🇮',
    BF: '🇧🇫',
    NE: '🇳🇪',
    TG: '🇹🇬',
    BJ: '🇧🇯',
    MU: '🇲🇺',
    LR: '🇱🇷',
    SL: '🇸🇱',
    GH: '🇬🇭',
    NG: '🇳🇬',
    TD: '🇹🇩',
    CF: '🇨🇫',
    CM: '🇨🇲',
    CV: '🇨🇻',
    ST: '🇸🇹',
    GQ: '🇬🇶',
    GA: '🇬🇦',
    CG: '🇨🇬',
    CD: '🇨🇩',
    AO: '🇦🇴',
    GW: '🇬🇼',
    IO: '🇮🇴',
    SC: '🇸🇨',
    SD: '🇸🇩',
    RW: '🇷🇼',
    ET: '🇪🇹',
    SO: '🇸🇴',
    DJ: '🇩🇯',
    KE: '🇰🇪',
    TZ: '🇹🇿',
    UG: '🇺🇬',
    BI: '🇧🇮',
    MZ: '🇲🇿',
    ZM: '🇿🇲',
    MG: '🇲🇬',
    YT: '🇾🇹',
    RE: '🇷🇪',
    ZW: '🇿🇼',
    NA: '🇳🇦',
    MW: '🇲🇼',
    LS: '🇱🇸',
    BW: '🇧🇼',
    SZ: '🇸🇿',
    KM: '🇰🇲',
    SH: '🇸🇭',
    ER: '🇪🇷',
    AW: '🇦🇼',
    FO: '🇫🇴',
    GL: '🇬🇱',
    KY: '🇰🇾',
    GI: '🇬🇮',
    PT: '🇵🇹',
    LU: '🇱🇺',
    IE: '🇮🇪',
    IS: '🇮🇸',
    AL: '🇦🇱',
    MT: '🇲🇹',
    CY: '🇨🇾',
    AX: '🇦🇽',
    FI: '🇫🇮',
    BG: '🇧🇬',
    LT: '🇱🇹',
    LV: '🇱🇻',
    EE: '🇪🇪',
    MD: '🇲🇩',
    AM: '🇦🇲',
    BY: '🇧🇾',
    AD: '🇦🇩',
    MC: '🇲🇨',
    SM: '🇸🇲',
    VA: '🇻🇦',
    UA: '🇺🇦',
    RS: '🇷🇸',
    ME: '🇲🇪',
    HR: '🇭🇷',
    SI: '🇸🇮',
    BA: '🇧🇦',
    MK: '🇲🇰',
    CZ: '🇨🇿',
    SK: '🇸🇰',
    LI: '🇱🇮',
    FK: '🇫🇰',
    GS: '🇬🇸',
    BZ: '🇧🇿',
    GT: '🇬🇹',
    SV: '🇸🇻',
    HN: '🇭🇳',
    NI: '🇳🇮',
    CR: '🇨🇷',
    PA: '🇵🇦',
    PM: '🇵🇲',
    HT: '🇭🇹',
    GP: '🇬🇵',
    BL: '🇧🇱',
    MF: '🇫🇷',
    BO: '🇧🇴',
    EC: '🇪🇨',
    GF: '🇬🇫',
    GY: '🇬🇾',
    PY: '🇵🇾',
    MQ: '🇲🇶',
    SR: '🇸🇷',
    UY: '🇺🇾',
    AN: '🇳🇱',
    TL: '🇹🇱',
    AQ: '🇦🇶',
    NF: '🇳🇫',
    BN: '🇧🇳',
    NR: '🇳🇷',
    PG: '🇵🇬',
    TO: '🇹🇴',
    SB: '🇸🇧',
    VU: '🇻🇺',
    FJ: '🇫🇯',
    PW: '🇵🇼',
    WF: '🇼🇫',
    CK: '🇨🇰',
    NU: '🇳🇺',
    WS: '🇼🇸',
    KI: '🇰🇮',
    NC: '🇳🇨',
    TV: '🇹🇻',
    PF: '🇵🇫',
    TK: '🇹🇰',
    FM: '🇫🇲',
    MH: '🇲🇭',
    KP: '🇰🇵',
    HK: '🇭🇰',
    MO: '🇲🇴',
    KH: '🇰🇭',
    LA: '🇱🇦',
    PN: '🇵🇳',
    BD: '🇧🇩',
    TW: '🇹🇼',
    MV: '🇲🇻',
    LB: '🇱🇧',
    JO: '🇯🇴',
    SY: '🇸🇾',
    IQ: '🇮🇶',
    KW: '🇰🇼',
    SA: '🇸🇦',
    YE: '🇾🇪',
    OM: '🇴🇲',
    PS: '🇵🇸',
    AE: '🇦🇪',
    IL: '🇮🇱',
    BH: '🇧🇭',
    QA: '🇶🇦',
    BT: '🇧🇹',
    MN: '🇲🇳',
    NP: '🇳🇵',
    TJ: '🇹🇯',
    TM: '🇹🇲',
    AZ: '🇦🇿',
    GE: '🇬🇪',
    KG: '🇰🇬',
    UZ: '🇺🇿',
    BS: '🇧🇸',
    BB: '🇧🇧',
    AI: '🇦🇮',
    AG: '🇦🇬',
    VG: '🇻🇬',
    VI: '🇻🇮',
    BM: '🇧🇲',
    GD: '🇬🇩',
    TC: '🇹🇨',
    MS: '🇲🇸',
    MP: '🇲🇵',
    GU: '🇬🇺',
    AS: '🇦🇸',
    LC: '🇱🇨',
    DM: '🇩🇲',
    VC: '🇻🇨',
    DO: '🇩🇴',
    TT: '🇹🇹',
    KN: '🇰🇳',
    JM: '🇯🇲',
    PR: '🇵🇷',
};
export const NATIONALITY = [
    {
        id: 1,
        label: 'Afghan',
        value: 'Afghan',
    },
    {
        id: 2,
        label: 'Aland Island',
        value: 'Aland Island',
    },
    {
        id: 3,
        label: 'Albanian',
        value: 'Albanian',
    },
    {
        id: 4,
        label: 'Algerian',
        value: 'Algerian',
    },
    {
        id: 5,
        label: 'American Samoan',
        value: 'American Samoan',
    },
    {
        id: 6,
        label: 'Andorran',
        value: 'Andorran',
    },
    {
        id: 7,
        label: 'Angolan',
        value: 'Angolan',
    },
    {
        id: 8,
        label: 'Anguillan',
        value: 'Anguillan',
    },
    {
        id: 9,
        label: 'Antarctic',
        value: 'Antarctic',
    },
    {
        id: 10,
        label: 'Antiguan or Barbudan',
        value: 'Antiguan or Barbudan',
    },
    {
        id: 11,
        label: 'Argentine',
        value: 'Argentine',
    },
    {
        id: 12,
        label: 'Armenian',
        value: 'Armenian',
    },
    {
        id: 13,
        label: 'Aruban',
        value: 'Aruban',
    },
    {
        id: 14,
        label: 'Australian',
        value: 'Australian',
    },
    {
        id: 15,
        label: 'Austrian',
        value: 'Austrian',
    },
    {
        id: 16,
        label: 'Azerbaijani, Azeri',
        value: 'Azerbaijani, Azeri',
    },
    {
        id: 17,
        label: 'Bahamian',
        value: 'Bahamian',
    },
    {
        id: 18,
        label: 'Bahraini',
        value: 'Bahraini',
    },
    {
        id: 19,
        label: 'Bangladeshi',
        value: 'Bangladeshi',
    },
    {
        id: 20,
        label: 'Barbadian',
        value: 'Barbadian',
    },
    {
        id: 21,
        label: 'Belarusian',
        value: 'Belarusian',
    },
    {
        id: 22,
        label: 'Belgian',
        value: 'Belgian',
    },
    {
        id: 23,
        label: 'Belizean',
        value: 'Belizean',
    },
    {
        id: 24,
        label: 'Beninese, Beninois',
        value: 'Beninese, Beninois',
    },
    {
        id: 25,
        label: 'Bermudian, Bermudan',
        value: 'Bermudian, Bermudan',
    },
    {
        id: 26,
        label: 'Bhutanese',
        value: 'Bhutanese',
    },
    {
        id: 27,
        label: 'Bolivian',
        value: 'Bolivian',
    },
    {
        id: 28,
        label: 'Bonaire',
        value: 'Bonaire',
    },
    {
        id: 29,
        label: 'Bosnian or Herzegovinian',
        value: 'Bosnian or Herzegovinian',
    },
    {
        id: 30,
        label: 'Motswana, Botswanan',
        value: 'Motswana, Botswanan',
    },
    {
        id: 31,
        label: 'Bouvet Island',
        value: 'Bouvet Island',
    },
    {
        id: 32,
        label: 'Brazilian',
        value: 'Brazilian',
    },
    {
        id: 33,
        label: 'BIOT',
        value: 'BIOT',
    },
    {
        id: 34,
        label: 'Bruneian',
        value: 'Bruneian',
    },
    {
        id: 35,
        label: 'Bulgarian',
        value: 'Bulgarian',
    },
    {
        id: 36,
        label: 'Burkinabé',
        value: 'Burkinabé',
    },
    {
        id: 37,
        label: 'Burundian',
        value: 'Burundian',
    },
    {
        id: 38,
        label: 'Cabo Verdean',
        value: 'Cabo Verdean',
    },
    {
        id: 39,
        label: 'Cambodian',
        value: 'Cambodian',
    },
    {
        id: 40,
        label: 'Cameroonian',
        value: 'Cameroonian',
    },
    {
        id: 41,
        label: 'Canadian',
        value: 'Canadian',
    },
    {
        id: 42,
        label: 'Caymanian',
        value: 'Caymanian',
    },
    {
        id: 43,
        label: 'Central African',
        value: 'Central African',
    },
    {
        id: 44,
        label: 'Chadian',
        value: 'Chadian',
    },
    {
        id: 45,
        label: 'Chilean',
        value: 'Chilean',
    },
    {
        id: 46,
        label: 'Chinese',
        value: 'Chinese',
    },
    {
        id: 47,
        label: 'Christmas Island',
        value: 'Christmas Island',
    },
    {
        id: 48,
        label: 'Cocos Island',
        value: 'Cocos Island',
    },
    {
        id: 49,
        label: 'Colombian',
        value: 'Colombian',
    },
    {
        id: 50,
        label: 'Comoran, Comorian',
        value: 'Comoran, Comorian',
    },
    {
        id: 51,
        label: 'Congolese',
        value: 'Congolese',
    },
    {
        id: 52,
        label: 'Congolese',
        value: 'Congolese',
    },
    {
        id: 53,
        label: 'Cook Island',
        value: 'Cook Island',
    },
    {
        id: 54,
        label: 'Costa Rican',
        value: 'Costa Rican',
    },
    {
        id: 55,
        label: 'Ivorian',
        value: 'Ivorian',
    },
    {
        id: 56,
        label: 'Croatian',
        value: 'Croatian',
    },
    {
        id: 57,
        label: 'Cuban',
        value: 'Cuban',
    },
    {
        id: 58,
        label: 'Curaçaoan',
        value: 'Curaçaoan',
    },
    {
        id: 59,
        label: 'Cypriot',
        value: 'Cypriot',
    },
    {
        id: 60,
        label: 'Czech',
        value: 'Czech',
    },
    {
        id: 61,
        label: 'Danish',
        value: 'Danish',
    },
    {
        id: 62,
        label: 'Djiboutian',
        value: 'Djiboutian',
    },
    {
        id: 63,
        label: 'Dominican',
        value: 'Dominican',
    },
    {
        id: 64,
        label: 'Dominican',
        value: 'Dominican',
    },
    {
        id: 65,
        label: 'Ecuadorian',
        value: 'Ecuadorian',
    },
    {
        id: 66,
        label: 'Egyptian',
        value: 'Egyptian',
    },
    {
        id: 67,
        label: 'Salvadoran',
        value: 'Salvadoran',
    },
    {
        id: 68,
        label: 'Equatorial Guinean, Equatoguinean',
        value: 'Equatorial Guinean, Equatoguinean',
    },
    {
        id: 69,
        label: 'Eritrean',
        value: 'Eritrean',
    },
    {
        id: 70,
        label: 'Estonian',
        value: 'Estonian',
    },
    {
        id: 71,
        label: 'Ethiopian',
        value: 'Ethiopian',
    },
    {
        id: 72,
        label: 'Falkland Island',
        value: 'Falkland Island',
    },
    {
        id: 73,
        label: 'Faroese',
        value: 'Faroese',
    },
    {
        id: 74,
        label: 'Fijian',
        value: 'Fijian',
    },
    {
        id: 75,
        label: 'Finnish',
        value: 'Finnish',
    },
    {
        id: 76,
        label: 'French',
        value: 'French',
    },
    {
        id: 77,
        label: 'French Guianese',
        value: 'French Guianese',
    },
    {
        id: 78,
        label: 'French Polynesian',
        value: 'French Polynesian',
    },
    {
        id: 79,
        label: 'French Southern Territories',
        value: 'French Southern Territories',
    },
    {
        id: 80,
        label: 'Gabonese',
        value: 'Gabonese',
    },
    {
        id: 81,
        label: 'Gambian',
        value: 'Gambian',
    },
    {
        id: 82,
        label: 'Georgian',
        value: 'Georgian',
    },
    {
        id: 83,
        label: 'German',
        value: 'German',
    },
    {
        id: 84,
        label: 'Ghanaian',
        value: 'Ghanaian',
    },
    {
        id: 85,
        label: 'Gibraltar',
        value: 'Gibraltar',
    },
    {
        id: 86,
        label: 'Greek, Hellenic',
        value: 'Greek, Hellenic',
    },
    {
        id: 87,
        label: 'Greenlandic',
        value: 'Greenlandic',
    },
    {
        id: 88,
        label: 'Grenadian',
        value: 'Grenadian',
    },
    {
        id: 89,
        label: 'Guadeloupe',
        value: 'Guadeloupe',
    },
    {
        id: 90,
        label: 'Guamanian, Guambat',
        value: 'Guamanian, Guambat',
    },
    {
        id: 91,
        label: 'Guatemalan',
        value: 'Guatemalan',
    },
    {
        id: 92,
        label: 'Channel Island',
        value: 'Channel Island',
    },
    {
        id: 93,
        label: 'Guinean',
        value: 'Guinean',
    },
    {
        id: 94,
        label: 'Bissau-Guinean',
        value: 'Bissau-Guinean',
    },
    {
        id: 95,
        label: 'Guyanese',
        value: 'Guyanese',
    },
    {
        id: 96,
        label: 'Haitian',
        value: 'Haitian',
    },
    {
        id: 97,
        label: 'Heard Island or McDonald Islands',
        value: 'Heard Island or McDonald Islands',
    },
    {
        id: 98,
        label: 'Vatican',
        value: 'Vatican',
    },
    {
        id: 99,
        label: 'Honduran',
        value: 'Honduran',
    },
    {
        id: 100,
        label: 'Hong Kong, Hong Kongese',
        value: 'Hong Kong, Hong Kongese',
    },
    {
        id: 101,
        label: 'Hungarian, Magyar',
        value: 'Hungarian, Magyar',
    },
    {
        id: 102,
        label: 'Icelandic',
        value: 'Icelandic',
    },
    {
        id: 103,
        label: 'Indian',
        value: 'Indian',
    },
    {
        id: 104,
        label: 'Indonesian',
        value: 'Indonesian',
    },
    {
        id: 105,
        label: 'Iranian, Persian',
        value: 'Iranian, Persian',
    },
    {
        id: 106,
        label: 'Iraqi',
        value: 'Iraqi',
    },
    {
        id: 107,
        label: 'Irish',
        value: 'Irish',
    },
    {
        id: 108,
        label: 'Manx',
        value: 'Manx',
    },
    {
        id: 109,
        label: 'Israeli',
        value: 'Israeli',
    },
    {
        id: 110,
        label: 'Italian',
        value: 'Italian',
    },
    {
        id: 111,
        label: 'Jamaican',
        value: 'Jamaican',
    },
    {
        id: 112,
        label: 'Japanese',
        value: 'Japanese',
    },
    {
        id: 113,
        label: 'Channel Island',
        value: 'Channel Island',
    },
    {
        id: 114,
        label: 'Jordanian',
        value: 'Jordanian',
    },
    {
        id: 115,
        label: 'Kazakhstani, Kazakh',
        value: 'Kazakhstani, Kazakh',
    },
    {
        id: 116,
        label: 'Kenyan',
        value: 'Kenyan',
    },
    {
        id: 117,
        label: 'I-Kiribati',
        value: 'I-Kiribati',
    },
    {
        id: 118,
        label: 'North Korean',
        value: 'North Korean',
    },
    {
        id: 119,
        label: 'South Korean',
        value: 'South Korean',
    },
    {
        id: 120,
        label: 'Kuwaiti',
        value: 'Kuwaiti',
    },
    {
        id: 121,
        label: 'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz',
        value: 'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz',
    },
    {
        id: 122,
        label: 'Lao, Laotian',
        value: 'Lao, Laotian',
    },
    {
        id: 123,
        label: 'Latvian',
        value: 'Latvian',
    },
    {
        id: 124,
        label: 'Lebanese',
        value: 'Lebanese',
    },
    {
        id: 125,
        label: 'Basotho',
        value: 'Basotho',
    },
    {
        id: 126,
        label: 'Liberian',
        value: 'Liberian',
    },
    {
        id: 127,
        label: 'Libyan',
        value: 'Libyan',
    },
    {
        id: 128,
        label: 'Liechtenstein',
        value: 'Liechtenstein',
    },
    {
        id: 129,
        label: 'Lithuanian',
        value: 'Lithuanian',
    },
    {
        id: 130,
        label: 'Luxembourg, Luxembourgish',
        value: 'Luxembourg, Luxembourgish',
    },
    {
        id: 131,
        label: 'Macanese, Chinese',
        value: 'Macanese, Chinese',
    },
    {
        id: 132,
        label: 'Macedonian',
        value: 'Macedonian',
    },
    {
        id: 133,
        label: 'Malagasy',
        value: 'Malagasy',
    },
    {
        id: 134,
        label: 'Malawian',
        value: 'Malawian',
    },
    {
        id: 135,
        label: 'Malaysian',
        value: 'Malaysian',
    },
    {
        id: 136,
        label: 'Maldivian',
        value: 'Maldivian',
    },
    {
        id: 137,
        label: 'Malian, Malinese',
        value: 'Malian, Malinese',
    },
    {
        id: 138,
        label: 'Maltese',
        value: 'Maltese',
    },
    {
        id: 139,
        label: 'Marshallese',
        value: 'Marshallese',
    },
    {
        id: 140,
        label: 'Martiniquais, Martinican',
        value: 'Martiniquais, Martinican',
    },
    {
        id: 141,
        label: 'Mauritanian',
        value: 'Mauritanian',
    },
    {
        id: 142,
        label: 'Mauritian',
        value: 'Mauritian',
    },
    {
        id: 143,
        label: 'Mahoran',
        value: 'Mahoran',
    },
    {
        id: 144,
        label: 'Mexican',
        value: 'Mexican',
    },
    {
        id: 145,
        label: 'Micronesian',
        value: 'Micronesian',
    },
    {
        id: 146,
        label: 'Moldovan',
        value: 'Moldovan',
    },
    {
        id: 147,
        label: 'Monégasque, Monacan',
        value: 'Monégasque, Monacan',
    },
    {
        id: 148,
        label: 'Mongolian',
        value: 'Mongolian',
    },
    {
        id: 149,
        label: 'Montenegrin',
        value: 'Montenegrin',
    },
    {
        id: 150,
        label: 'Montserratian',
        value: 'Montserratian',
    },
    {
        id: 151,
        label: 'Moroccan',
        value: 'Moroccan',
    },
    {
        id: 152,
        label: 'Mozambican',
        value: 'Mozambican',
    },
    {
        id: 153,
        label: 'Burmese',
        value: 'Burmese',
    },
    {
        id: 154,
        label: 'Namibian',
        value: 'Namibian',
    },
    {
        id: 155,
        label: 'Nauruan',
        value: 'Nauruan',
    },
    {
        id: 156,
        label: 'Nepali, Nepalese',
        value: 'Nepali, Nepalese',
    },
    {
        id: 157,
        label: 'Dutch, Netherlandic',
        value: 'Dutch, Netherlandic',
    },
    {
        id: 158,
        label: 'New Caledonian',
        value: 'New Caledonian',
    },
    {
        id: 159,
        label: 'New Zealand, NZ',
        value: 'New Zealand, NZ',
    },
    {
        id: 160,
        label: 'Nicaraguan',
        value: 'Nicaraguan',
    },
    {
        id: 161,
        label: 'Nigerien',
        value: 'Nigerien',
    },
    {
        id: 162,
        label: 'Nigerian',
        value: 'Nigerian',
    },
    {
        id: 163,
        label: 'Niuean',
        value: 'Niuean',
    },
    {
        id: 164,
        label: 'Norfolk Island',
        value: 'Norfolk Island',
    },
    {
        id: 165,
        label: 'Northern Marianan',
        value: 'Northern Marianan',
    },
    {
        id: 166,
        label: 'Norwegian',
        value: 'Norwegian',
    },
    {
        id: 167,
        label: 'Omani',
        value: 'Omani',
    },
    {
        id: 168,
        label: 'Pakistani',
        value: 'Pakistani',
    },
    {
        id: 169,
        label: 'Palauan',
        value: 'Palauan',
    },
    {
        id: 170,
        label: 'Palestinian',
        value: 'Palestinian',
    },
    {
        id: 171,
        label: 'Panamanian',
        value: 'Panamanian',
    },
    {
        id: 172,
        label: 'Papua New Guinean, Papuan',
        value: 'Papua New Guinean, Papuan',
    },
    {
        id: 173,
        label: 'Paraguayan',
        value: 'Paraguayan',
    },
    {
        id: 174,
        label: 'Peruvian',
        value: 'Peruvian',
    },
    {
        id: 175,
        label: 'Philippine, Filipino',
        value: 'Philippine, Filipino',
    },
    {
        id: 176,
        label: 'Pitcairn Island',
        value: 'Pitcairn Island',
    },
    {
        id: 177,
        label: 'Polish',
        value: 'Polish',
    },
    {
        id: 178,
        label: 'Portuguese',
        value: 'Portuguese',
    },
    {
        id: 179,
        label: 'Puerto Rican',
        value: 'Puerto Rican',
    },
    {
        id: 180,
        label: 'Qatari',
        value: 'Qatari',
    },
    {
        id: 181,
        label: 'Réunionese, Réunionnais',
        value: 'Réunionese, Réunionnais',
    },
    {
        id: 182,
        label: 'Romanian',
        value: 'Romanian',
    },
    {
        id: 183,
        label: 'Russian',
        value: 'Russian',
    },
    {
        id: 184,
        label: 'Rwandan',
        value: 'Rwandan',
    },
    {
        id: 185,
        label: 'Barthélemois',
        value: 'Barthélemois',
    },
    {
        id: 186,
        label: 'Saint Helenian',
        value: 'Saint Helenian',
    },
    {
        id: 187,
        label: 'Kittitian or Nevisian',
        value: 'Kittitian or Nevisian',
    },
    {
        id: 188,
        label: 'Saint Lucian',
        value: 'Saint Lucian',
    },
    {
        id: 189,
        label: 'Saint-Martinoise',
        value: 'Saint-Martinoise',
    },
    {
        id: 190,
        label: 'Saint-Pierrais or Miquelonnais',
        value: 'Saint-Pierrais or Miquelonnais',
    },
    {
        id: 191,
        label: 'Saint Vincentian, Vincentian',
        value: 'Saint Vincentian, Vincentian',
    },
    {
        id: 192,
        label: 'Samoan',
        value: 'Samoan',
    },
    {
        id: 193,
        label: 'Sammarinese',
        value: 'Sammarinese',
    },
    {
        id: 194,
        label: 'São Toméan',
        value: 'São Toméan',
    },
    {
        id: 195,
        label: 'Saudi, Saudi Arabian',
        value: 'Saudi, Saudi Arabian',
    },
    {
        id: 196,
        label: 'Senegalese',
        value: 'Senegalese',
    },
    {
        id: 197,
        label: 'Serbian',
        value: 'Serbian',
    },
    {
        id: 198,
        label: 'Seychellois',
        value: 'Seychellois',
    },
    {
        id: 199,
        label: 'Sierra Leonean',
        value: 'Sierra Leonean',
    },
    {
        id: 200,
        label: 'Singaporean',
        value: 'Singaporean',
    },
    {
        id: 201,
        label: 'Sint Maarten',
        value: 'Sint Maarten',
    },
    {
        id: 202,
        label: 'Slovak',
        value: 'Slovak',
    },
    {
        id: 203,
        label: 'Slovenian, Slovene',
        value: 'Slovenian, Slovene',
    },
    {
        id: 204,
        label: 'Solomon Island',
        value: 'Solomon Island',
    },
    {
        id: 205,
        label: 'Somali, Somalian',
        value: 'Somali, Somalian',
    },
    {
        id: 206,
        label: 'South African',
        value: 'South African',
    },
    {
        id: 207,
        label: 'South Georgia or South Sandwich Islands',
        value: 'South Georgia or South Sandwich Islands',
    },
    {
        id: 208,
        label: 'South Sudanese',
        value: 'South Sudanese',
    },
    {
        id: 209,
        label: 'Spanish',
        value: 'Spanish',
    },
    {
        id: 210,
        label: 'Sri Lankan',
        value: 'Sri Lankan',
    },
    {
        id: 211,
        label: 'Sudanese',
        value: 'Sudanese',
    },
    {
        id: 212,
        label: 'Surinamese',
        value: 'Surinamese',
    },
    {
        id: 213,
        label: 'Svalbard',
        value: 'Svalbard',
    },
    {
        id: 214,
        label: 'Swazi',
        value: 'Swazi',
    },
    {
        id: 215,
        label: 'Swedish',
        value: 'Swedish',
    },
    {
        id: 216,
        label: 'Swiss',
        value: 'Swiss',
    },
    {
        id: 217,
        label: 'Syrian',
        value: 'Syrian',
    },
    {
        id: 218,
        label: 'Chinese, Taiwanese',
        value: 'Chinese, Taiwanese',
    },
    {
        id: 219,
        label: 'Tajikistani',
        value: 'Tajikistani',
    },
    {
        id: 220,
        label: 'Tanzanian',
        value: 'Tanzanian',
    },
    {
        id: 221,
        label: 'Thai',
        value: 'Thai',
    },
    {
        id: 222,
        label: 'Timorese',
        value: 'Timorese',
    },
    {
        id: 223,
        label: 'Togolese',
        value: 'Togolese',
    },
    {
        id: 224,
        label: 'Tokelauan',
        value: 'Tokelauan',
    },
    {
        id: 225,
        label: 'Tongan',
        value: 'Tongan',
    },
    {
        id: 226,
        label: 'Trinidadian or Tobagonian',
        value: 'Trinidadian or Tobagonian',
    },
    {
        id: 227,
        label: 'Tunisian',
        value: 'Tunisian',
    },
    {
        id: 228,
        label: 'Turkish',
        value: 'Turkish',
    },
    {
        id: 229,
        label: 'Turkmen',
        value: 'Turkmen',
    },
    {
        id: 230,
        label: 'Turks and Caicos Island',
        value: 'Turks and Caicos Island',
    },
    {
        id: 231,
        label: 'Tuvaluan',
        value: 'Tuvaluan',
    },
    {
        id: 232,
        label: 'Ugandan',
        value: 'Ugandan',
    },
    {
        id: 233,
        label: 'Ukrainian',
        value: 'Ukrainian',
    },
    {
        id: 234,
        label: 'Emirati, Emirian, Emiri',
        value: 'Emirati, Emirian, Emiri',
    },
    {
        id: 235,
        label: 'British, UK',
        value: 'British, UK',
    },
    {
        id: 236,
        label: 'American',
        value: 'American',
    },
    {
        id: 238,
        label: 'Uruguayan',
        value: 'Uruguayan',
    },
    {
        id: 239,
        label: 'Uzbekistani, Uzbek',
        value: 'Uzbekistani, Uzbek',
    },
    {
        id: 240,
        label: 'Ni-Vanuatu, Vanuatuan',
        value: 'Ni-Vanuatu, Vanuatuan',
    },
    {
        id: 241,
        label: 'Venezuelan',
        value: 'Venezuelan',
    },
    {
        id: 242,
        label: 'Vietnamese',
        value: 'Vietnamese',
    },
    {
        id: 243,
        label: 'British Virgin Island',
        value: 'British Virgin Island',
    },
    {
        id: 244,
        label: 'U.S. Virgin Island',
        value: 'U.S. Virgin Island',
    },
    {
        id: 245,
        label: 'Wallis and Futuna, Wallisian or Futunan',
        value: 'Wallis and Futuna, Wallisian or Futunan',
    },
    {
        id: 246,
        label: 'Sahrawi, Sahrawian, Sahraouian',
        value: 'Sahrawi, Sahrawian, Sahraouian',
    },
    {
        id: 247,
        label: 'Yemeni',
        value: 'Yemeni',
    },
    {
        id: 248,
        label: 'Zambian',
        value: 'Zambian',
    },
    {
        id: 249,
        label: 'Zimbabwean',
        value: 'Zimbabwean',
    },
];
const textKeys = ['testKey'];

export const directory = {
    en: EN,
    ar: AR,
    fr: FR,
    ru: RU,
    cn: CN,
    ko: KO,
    ja: JA
};
// generator function

// const generatedKeysData = (keysList,directory)=>{
//     const lang = ['US',"AR"];
//     const datares = lang.reduce((acc,item)=>{
//             keysList.forEach(keyName=>{
//                 if(!acc[item][keyName]){
//                     acc[item][keyName] = ''
//                 }

//             })
//         return acc
//     },directory)
// return datares

// }
